import React, { useEffect } from 'react';
import { Box, Flex, Image, Text, useMediaQuery } from '@chakra-ui/core';
import { colors } from '@theme';
import { navigate } from 'gatsby';
import {
  KeyFeatures,
  FamiliesHeartZeta,
  Metadata,
  Anchor,
  HelpfulResources,
} from '@familyaccounts';
import ContentContainer from '../components/ContentContainer';
import { cloudfrontBaseUrl, FamilyFAQContent } from '../lib/consts';
import {
  APP_CONTENT_SMALL_DESKTOP_WIDTH,
  APP_CONTENT_DESKTOP_WIDTH,
  APP_CONTENT_WIDE_WIDTH,
  APP_CONTENT_MOBILE_WIDTH,
} from '../styles/sizes';
import VIP from '../components/jointAccount/VIP';
import CardFeatures from '../components/jointAccount/cardFeatures';
import HowItWorks from '../components/familyAccount/HowItWorks';
import FAQs from '../components/jointAccount/FAQs';
import Lottie from 'react-lottie';
import FamilyHeroAnimation from '../lotties/family-hero-animation-1.json';
import ZetaIsFor from '../components/zeta/ZetaIsFor';
import AccountTabs from '../components/AccountTabs';

const FamilyBankAccounts = props => {
  return (
    <>
      <Metadata statusBarColor="#FDF3F3" />
      <AccountTabs defaultTab={2} />;
    </>
  );
};

export default FamilyBankAccounts;
